/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@swup/preload-plugin@1.0.3/dist/SwupPreloadPlugin.min.js
 * - /npm/@swup/ga-plugin@1.0.1/dist/SwupGaPlugin.min.js
 * - /npm/@swup/js-plugin@1.0.4/dist/SwupJsPlugin.min.js
 * - /npm/@swup/scroll-plugin@1.0.6/dist/SwupScrollPlugin.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
